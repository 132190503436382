import { render, staticRenderFns } from "./skills-message.vue?vue&type=template&id=e04e6f56&scoped=true&"
import script from "./skills-message.vue?vue&type=script&lang=js&"
export * from "./skills-message.vue?vue&type=script&lang=js&"
import style0 from "./skills-message.vue?vue&type=style&index=0&id=e04e6f56&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e04e6f56",
  null
  
)

export default component.exports